<template>
    <b-container fluid>
        <iq-card>
             <template v-slot:body>
                <b-row>
                    <b-col class="col-lg-6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('seedFer.purchase_requisition')"
                            label-for="id"
                        >
                            <v-select name="godown_Info"
                                v-model="search.godown_Info_name"
                                label="text"
                                :options= godownInfoList
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('seedFer.sales_center')"
                            label-for="sale_center_id"
                        >
                            <v-select name="godown_Info"
                                v-model="search.sale_center_name"
                                label="text"
                                :options= SalesCenterList
                                id="sale_center_id"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-6">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_division.division')"
                        label-for="division_id"
                        >
                        <b-form-select
                            plain
                            v-model="search.division_id"
                            :options="divisionList"
                            id="division_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col  class="col-lg-6">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_district.district')"
                        label-for="district_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.district_id"
                        :options="districtList"
                        id="district_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-sm-2">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('seedFer.purchase_requisition')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                        <!-- <b-button variant="danger" v-b-modal.modal-5>
                             {{  $t('globalTrans.excelImport') }}
                        </b-button> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_view" title="Purchase Requisition" v-b-modal.modal-4 @click="edit(data.item)"><i class="fas fa-eye"></i></a>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { purchaseRequistionlist, purchaseRequistionToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                godown_Info_name: null,
                id: 0,
                sale_center_name: null,
                sales_center_id: 0,
                division_id: 0,
                district_id: 0
            },
            districtList: [],
            upazilaList: []
        }
    },
    computed: {
        formTitle () {
            return (this.editItemId === 0) ? this.$t('seedFer.purchase_requisition') : this.$t('seedFer.purchase_requisition')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('seedFer.fiscal_year'), class: 'text-left' },
                { label: this.$t('seedFer.org_name'), class: 'text-left' },
                { label: this.$t('seedFer.sales_office'), class: 'text-left' },
                { label: this.$t('fertilizerConfig.fertilizer_type'), class: 'text-left' },
                { label: this.$t('fertilizerConfig.fertilizer_name'), class: 'text-left' },
                { label: this.$t('irrigation_config.unit'), class: 'text-left' },
                { label: this.$t('pump_install.quantity'), class: 'text-center' },
                { label: this.$t('globalTrans.view'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year' },
                { key: 'org_name_bn' },
                { key: 'office_name_bn' },
                { key: 'fertilizer_type_name_bn' },
                { key: 'fertilizer_name_bn' },
                { key: 'unit_name' },
                { key: 'quantity' },
                { key: 'action' },
                { key: 'status' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year' },
                { key: 'org_name' },
                { key: 'office_name' },
                { key: 'fertilizer_type_name' },
                { key: 'fertilizer_name' },
                { key: 'unit_name_bn' },
                { key: 'quantity' },
                { key: 'action' },
                { key: 'status' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList
        },
        SalesCenterList: function () {
            return this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 73)
        },
        godownInfoList: function () {
            const godownInfoList = this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
            return godownInfoList
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
        'search.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        }
    },
    methods: {
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
                if (divisionId) {
                    return districtList.filter(district => district.division_id === divisionId)
                }
            return districtList
        },
        remove (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, purchaseRequistionToggleStatus, item, 'warehosue', 'commodityGroupList')
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                commodity_group_name: '',
                commodity_group_name_bn: '',
                editable: false
            }
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            if (this.search.godown_Info_name) {
                this.search.id = this.search.godown_Info_name.value
            }
            if (this.search.sale_center_name) {
                this.search.sales_center_id = this.search.sale_center_name.value
            }
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, purchaseRequistionlist, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
            const divisionList = this.$store.state.commonObj.divisionList
            const districtList = this.$store.state.commonObj.districtList
            const upazilaList = this.$store.state.commonObj.upazilaList
            const salesCenterList = this.SalesCenterList
            return data.data.map(item => {
                const divisionObject = divisionList.find(division => division.value === item.division_id)
                const districtObject = districtList.find(district => district.value === item.district_id)
                const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
                const salesCenterObject = salesCenterList.find(salesCenter => salesCenter.value === item.sales_center_id)
                const divisionData = {
                    division_name: divisionObject !== undefined ? divisionObject.text_en : '',
                    division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
                }
                const districtData = {
                    district_name: districtObject !== undefined ? districtObject.text_en : '',
                    district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
                }
                const upazillaData = {
                    upazilla_name: upazilaObject !== undefined ? upazilaObject.text_en : '',
                    upazilla_name_bn: upazilaObject !== undefined ? upazilaObject.text_bn : ''
                }
                const salesCenterData = {
                    sales_center_name: salesCenterObject !== undefined ? salesCenterObject.text_en : '',
                    sales_center_name_bn: salesCenterObject !== undefined ? salesCenterObject.text_en : ''
                }
                return Object.assign({}, item, divisionData, districtData, upazillaData, salesCenterData)
            })
        }
    }
}
</script>
