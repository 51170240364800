export const testApi = '/test'

export const lcCreatelist = '/fertilizer/procurements/fertilizer-lc-creates/list'
export const lcCreateStore = '/fertilizer/procurements/fertilizer-lc-creates/store'
export const LcCreateUpdate = '/fertilizer/procurements/fertilizer-lc-creates/update'
export const LcCreateToggleStatus = '/fertilizer/procurements/fertilizer-lc-creates/toggle-status'
export const LcCreateDestroy = '/fertilizer/procurements/fertilizer-lc-creates/destroy'
export const LcCreateShow = '/fertilizer/procurements/fertilizer-lc-creates/show'
export const LcCreateUpdateDateInfo = '/fertilizer/procurements/fertilizer-lc-creates/update-date-info'

// purchase Requistion
export const purchaseRequistionlist = '/fertilizer/procurements/purchase-requisition/list'
export const purchaseRequistionStore = '/fertilizer/procurements/purchase-requisition/store'
export const purchaseRequistionUpdate = '/fertilizer/procurements/purchase-requisition/update'
export const purchaseRequistionToggleStatus = '/fertilizer/procurements/purchase-requisition/toggle-status'
export const purchaseRequistionDestroy = '/fertilizer/procurements/purchase-requisition/destroy'
export const dealerInfo = 'fertilizer/config/dealer-setup/dealer-list'
export const purchaseRequistionaccept = '/fertilizer/procurements/purchase-requisition/accept'
export const purchaseRequistionreject = '/fertilizer/procurements/purchase-requisition/reject'
export const purchaseRequistiondetails = '/fertilizer/procurements/purchase-requisition/details'

// purchase requisition approve
export const warehouseUserSelectList = ''
