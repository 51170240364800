<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12" class="form-for-textarea">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Company Name"  vid="name_bn" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="company_name"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('seedFer.company_name')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="company_name"
                                      v-model="purchaseRequisition.company_name"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                       <b-col lg="6" sm="12">
                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('seedFer.fiscal_year') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisition.fiscal_year_id"
                              :options="fiscalYearList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Current Date" vid="current_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="current_date"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('seedFer.current_date') }} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                  class="date-picker"
                                  v-model="purchaseRequisition.current_date"
                                  placeholder="Select Date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Sale Office" vid="sale_center_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="sale_center_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('seedFer.sales_center') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisition.sale_center_id"
                              :options="SalesCenterList"
                              id="sale_center_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { purchaseRequistionStore, purchaseRequistionUpdate, warehouseUserSelectList } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getpurchaseRequisitionData()
      this.purchaseRequisition = tmp
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
    this.warehouseUserDataFn()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      purchaseRequisition: {
        org_id: '',
        type: '',
        dealer_com_farmer_id: '',
        company_name: '',
        fiscal_year_id: '',
        current_date: '',
        sale_center_id: '',
        fertilizer_type_id: '',
        fertilizer_name_id: '',
        unit_id: '',
        quantity: '',
        remarks: '',
        remarks_bn: ''
      },
      districtList: [],
      upazilaList: [],
      warehouseUserData: [],
      attachmentDemo: ''
    }
  },
  computed: {
    yearList: function () {
      var max = new Date().getFullYear()
      var min = max - 31
      var years = []
      for (var i = max; i >= min; i--) {
        const yearData = {}
        yearData.value = i
        yearData.text = this.$i18n.locale === 'bn' ? this.$n(i) : i
        yearData.text_en = i
        yearData.text_bn = i
        years.push(yearData)
      }
      return years
      // return this.$store.state.SeedsFertilizer.commonObj.yearList
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    SalesCenterList: function () {
      return this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 73)
    },
    WarehouseUserList: function () {
      const listObject = this.warehouseUserData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
        } else {
            return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    }
  },
  watch: {
    'purchaseRequisition.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'purchaseRequisition.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  methods: {
    warehouseUserDataFn () {
        RestApi.getData(seedFertilizerServiceBaseUrl, warehouseUserSelectList, {}).then(response => {
          this.warehouseUserData = response
        })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    onFileChange (e) {
      this.attachmentDemo = e.target.files[0]
    },
    getpurchaseRequisitionData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      if (this.purchaseRequisition.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${purchaseRequistionUpdate}/${this.id}`, this.purchaseRequisition)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, purchaseRequistionStore, this.purchaseRequisition)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.commit('mutateDropdownCommonConfig', { hasDropdownLoaded: false })
        this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
    }
  }
}
</script>
